<template>
  <div
    class="skill-card card p-2 flex-shrink-0 d-flex flex-column justify-content-center align-items-center"
  >
    <span class="skill-card__icon mb-2">
      <img
        v-if="skill.class === null"
        src="../../assets/img/skill-icon.png"
        class="img-fluid"
      />
      <i
        v-if="skill.class !== null"
        class="m-fs-30 colored"
        :class="skill.class"
      ></i>
    </span>

    <span class="skill-card__skill" :title="skill.name">{{ skill.name }}</span>

    <span v-if="!!skill.level" class="skill-card__level mt-2">
      <span
        class="skill-card__circle d-inline-block rounded-circle mr-2"
        :class="circleClass"
      ></span>
      {{ skill.level || "Junior" | capitalize }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    skill: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      levelColors: {
        intermediate: "bg-secondary",
        senior: "bg-success",
        junior: "bg-light",
      },
    };
  },
  computed: {
    circleClass() {
      return this.skill?.level !== undefined
        ? this.levelColors[this.skill.level]
        : this.levelColors["junior"];
    },
  },
};
</script>

<style lang="scss" scoped>
.skill-card {
  border-radius: $size-10;
  min-width: 150px;
  max-width: 150px;
  height: 138px;
  @include media-breakpoint-down(md) {
    max-width: 78px;
    min-width: 78px;
    height: 100px;
  }
  &__skill {
    font-size: 1rem;
    font-weight: 600;
    @include add-ellipsis;
    max-width: 12ch;
    @include media-breakpoint-down(md) {
      font-size: 0.875rem;
      max-width: 7ch;
    }
  }
  &__icon {
    @include media-breakpoint-down(md) {
      max-width: 20px;
    }
    i {
      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }
  }
  &__level {
    font-size: 0.625rem;
  }
  &__circle {
    width: 6px;
    height: 6px;
  }
  .bg-light {
    background-color: $grey-tint-2 !important;
  }
}
</style>
