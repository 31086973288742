<template>
  <svg
    width="28"
    height="23"
    viewBox="0 0 28 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.071 2.04117H7.63015C7.1828 2.04117 6.82015 1.67852 6.82015 1.23116V0.825861C6.82015 0.369759 6.45039 0 5.99428 0H1.65172C0.739518 0 0 0.739463 0 1.65172V15.5498L2.11931 5.23918C2.19674 4.86253 2.52822 4.59224 2.91274 4.59224H23.7228V3.69289C23.7228 2.78063 22.9832 2.04117 22.071 2.04117Z"
      fill="#B7BDCB"
    />
    <path
      d="M26.3474 6.21484H3.57542L0.388178 21.7208C0.283626 22.2296 0.672196 22.7062 1.19162 22.7062H24.8884C25.7303 22.7062 26.4375 22.0729 26.5301 21.2361L27.9891 8.04822C28.0974 7.07001 27.3315 6.21484 26.3474 6.21484Z"
      fill="#146CBB"
    />
  </svg>
</template>
<script>
export default {
  name: "FileIcon",
};
</script>
