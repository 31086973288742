<template>
   <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      fill="#ffffff"
      d="M22 19a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h4a1 1 0 0 1 0 2H5a1 1 0 0 0-1 1v13.278l4.186-5.86a1 1 0 0 1 1.355-.259l.1.073 5.144 4.286 1.383-2.073a1 1 0 0 1 1.446-.234l.093.082L20 16.585V15a1 1 0 0 1 2 0v4ZM9.187 14.458 5.228 20H19a1 1 0 0 0 .937-.65l-2.782-2.78-1.323 1.985a1 1 0 0 1-1.378.283l-.094-.07-5.173-4.31ZM17 2a1 1 0 0 1 1 1v3h3a1 1 0 0 1 0 2h-3v3a1 1 0 0 1-2 0V8h-3a1 1 0 0 1 0-2h3V3a1 1 0 0 1 1-1ZM8 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z"
    />
  </svg>
</template>
<script>
export default {
  name: "ImageUploader",
};
</script>
