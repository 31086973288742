<template>
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.74794 8.47555C2.74928 8.47555 2.75051 8.47555 2.75185 8.47555C2.86543 8.47325 2.95586 8.37354 2.95381 8.25259L2.85093 2.36603C2.84877 2.2452 2.75432 2.14603 2.64136 2.15128C2.52778 2.15358 2.43735 2.25329 2.4394 2.37424L2.54228 8.2608C2.54445 8.38022 2.63621 8.47555 2.74794 8.47555ZM4.25257 8.47555C4.3644 8.47555 4.45617 8.38022 4.45823 8.26069L4.56111 2.37413C4.56327 2.25329 4.47284 2.15347 4.35916 2.15118C4.35782 2.15118 4.35659 2.15118 4.35525 2.15118C4.24342 2.15118 4.15165 2.24651 4.14959 2.36603L4.04671 8.25259C4.04455 8.37343 4.13498 8.47325 4.24866 8.47555C4.25 8.47555 4.25134 8.47555 4.25257 8.47555ZM6.27377 2.15128C6.15597 2.14921 6.06512 2.24224 6.06152 2.36308L6.0322 3.33118C6.0286 3.45202 6.1177 3.55315 6.23128 3.55698C6.23354 3.55698 6.2358 3.55709 6.23796 3.55709C6.34856 3.55709 6.43992 3.46362 6.44352 3.34519L6.47284 2.37709C6.47644 2.25625 6.38724 2.15512 6.27377 2.15128ZM6.21955 3.94116C6.21728 3.94116 6.21502 3.94105 6.21286 3.94105C6.10226 3.94105 6.01091 4.03452 6.00731 4.15295L5.85556 9.17134C5.85556 9.38696 5.69084 9.56241 5.48858 9.56241H1.51235C1.30957 9.56241 1.14475 9.38696 1.14465 9.16422L0.938889 2.36286C0.935185 2.24202 0.842387 2.14855 0.726646 2.15107C0.613066 2.15501 0.523971 2.25603 0.527572 2.37687L0.733231 9.17112C0.733231 9.62819 1.08272 10 1.51235 10H5.48858C5.9179 10 6.26708 9.62819 6.26698 9.17812L6.41852 4.16663C6.42212 4.04612 6.33303 3.94499 6.21955 3.94116ZM6.79424 1.05676H5.13704L4.96523 0.174467C4.94547 0.0728952 4.86132 0 4.76379 0H2.23704C2.13951 0 2.05535 0.0728952 2.0356 0.174467L1.86379 1.05676H0.205761C0.0921811 1.05676 0 1.15483 0 1.27567C0 1.3965 0.0921811 1.49457 0.205761 1.49457H2.03086C2.03086 1.49457 2.03097 1.49457 2.03107 1.49457H4.96873C4.96893 1.49457 4.96914 1.49457 4.96934 1.49457C4.96944 1.49457 4.96965 1.49457 4.96986 1.49457H6.79424C6.90782 1.49457 7 1.3965 7 1.27567C7 1.15483 6.90782 1.05676 6.79424 1.05676ZM2.28405 1.05676L2.40463 0.437809H4.5964L4.71698 1.05676H2.28405Z"
      fill="#FA6752"
    />
  </svg>
</template>
<script>
export default {
  name: "DustbinIcon",
};
</script>
