<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6668 7.00016C13.6668 10.6821 10.6821 13.6668 7.00016 13.6668C3.31826 13.6668 0.333496 10.6821 0.333496 7.00016C0.333496 3.31826 3.31826 0.333496 7.00016 0.333496C10.6821 0.333496 13.6668 3.31826 13.6668 7.00016ZM7.00016 6.3335C7.36835 6.3335 7.66683 6.63197 7.66683 7.00016V10.3341C7.66683 10.7023 7.36835 11.0008 7.00016 11.0008C6.63197 11.0008 6.3335 10.7023 6.3335 10.3341V7.00016C6.3335 6.63197 6.63197 6.3335 7.00016 6.3335ZM7.00016 5.00016C7.36835 5.00016 7.66683 4.70169 7.66683 4.3335C7.66683 3.96531 7.36835 3.66683 7.00016 3.66683C6.63197 3.66683 6.3335 3.96531 6.3335 4.3335C6.3335 4.70169 6.63197 5.00016 7.00016 5.00016Z"
      fill="#9A9FA5"
    />
  </svg>
</template>
<script>
export default {
  name: "IIcon",
};
</script>
