<template>
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.207 9.29a1 1 0 0 1-1.414 1.415l-2.791-2.791V15a1 1 0 1 1-2 0V7.914l-2.793 2.793a1 1 0 0 1-1.414-1.415l4.5-4.5a1 1 0 0 1 1.414 0l4.498 4.498Z"
      fill="#1A1D1F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 14a1 1 0 0 1 1 1v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a1 1 0 1 1 2 0v2a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-2a1 1 0 0 1 1-1Z"
      fill="#1A1D1F"
    />
  </svg>
</template>
  <script>
  export default {
    name: "UploadIcon",
  };
  </script>
  