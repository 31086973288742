<template>
  <profile-information class="resume" title="Resume" @makeEdit="makeEdit">
    <template #body>
      <div class="d-flex flex-wrap">
        <ResumeCard v-if="resume !== null"
          :resume="{title: 'Resume', url: resume}"
          class="mb-2 mr-3"
        />
        <p v-if="resume === null">No resume added yet</p>
      </div>
    </template>
  </profile-information>
</template>

<script>
import ResumeCard from "./ResumeCard.vue";
import ProfileInformation from "./ProfileInformation.vue";
export default {
  name: "Resume",
  components: {
    ResumeCard,
    ProfileInformation,
  },
  props: {
    resume: {
      type: String,
      default: null
    },
  },
  methods: {
    makeEdit() {
      this.$emit("makeEdit");
    },
  },
};
</script>
