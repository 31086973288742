<template>
  <svg width="13" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.229 4.268c.043-.216.065-.427.065-.636C12.294 1.63 10.614 0 8.546 0a3.769 3.769 0 0 0-2.828 1.26C3.539.772 1.525 2.258 1.22 4.27A3.26 3.26 0 0 0 0 6.798c0 1.816 1.511 3.295 3.363 3.295v-1.35c-1.134 0-2.02-.857-2.02-1.948 0-.636.33-1.235.882-1.601l.28-.187.018-.337C2.61 3.198 4.1 2.082 5.74 2.66l.492.176.29-.435A2.415 2.415 0 0 1 8.54 1.342c1.325 0 2.401 1.027 2.401 2.287 0 .215-.043.445-.129.7l-.18.524.482.276c.624.36.998.98.998 1.662 0 1.077-.923 1.953-2.054 1.953h-2.66V6.723h1.347L6.73 4.709 4.71 6.73h1.345v3.367h4.006c1.874 0 3.4-1.479 3.4-3.299 0-.998-.453-1.91-1.232-2.53Z"
      fill="#A6A9AF"
    />
  </svg>
</template>
<script>
export default {
  name: "CloudIcon",
};
</script>
