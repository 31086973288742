<template>
  <primary-modal
    ref="editProfile"
    modal-id="edit_profile"
    :title="this.isLoading ? 'Please wait' : 'Edit Profile'"
  >
    <div class="edit-profile p-3">
      <b-form ref="editProfileForm" @submit="handleSubmit">
        <b-form-group label="First name" label-for="firstname">
          <b-form-input id="firstname" v-model="user.first_name"></b-form-input>
        </b-form-group>
        <b-form-group label="Last name" label-for="lastname">
          <b-form-input id="lastname" v-model="user.last_name"></b-form-input>
        </b-form-group>
        <b-form-group label="Email" Disabled label-for="email">
          <b-form-input
            id="email"
            disabled
            v-model="user.email"
            type="email"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Phone number" label-for="phone">
          <b-form-input id="phone" type="tel" v-model="user.phone"></b-form-input>
        </b-form-group>
        <b-form-group label="Date of birth" label-for="dob">
          <b-form-input id="dob" v-model="user.date_of_birth" type="date"></b-form-input>
        </b-form-group>
        <b-form-group label="Gender">
          <b-form-radio-group
            v-model="user.gender"
            class="d-flex justify-content-between overflow-auto"
            :options="['male', 'female', 'Others']"
          ></b-form-radio-group>
        </b-form-group>
        <b-button block type="submit" variant="primary" class="mt-5">
          {{ isLoading ? "" : "Submit" }}
          <RingLoader v-if="isLoading" />
        </b-button>
      </b-form>
    </div>
  </primary-modal>
</template>

<script>
import PrimaryModal from "@/components/PrimaryModal.vue";
import RingLoader from "@/components/loader/RingLoader";
export default {
  name: "EditProfile",
  components: {
    PrimaryModal,
    RingLoader,
  },
  props: {
    editData: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: this.editData,
      isLoading: this.isBusy
    };
  },
  watch: {
    isBusy(busy) {
      this.isLoading = busy
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.isLoading = true;
      this.$emit("updated", this.user)
    },
  },
};
</script>

<style lang="scss" scoped></style>
