<template>
  <primary-modal
    ref="editResume"
    modal-id="edit_resume"
    :title="this.isBusy ? 'Please wait' : 'Edit Resume'"
  >
    <b-form ref="editResumeForm">
      <BoxUploader v-model="form.resume" title="Upload Resume" />
      <b-button
        block
        type="button"
        variant="primary"
        class="mt-5"
        :disabled="btnDisabled"
        @click="handleSubmit"
      >
        {{ isBusy ? "" : "Submit" }}
        <RingLoader v-if="isBusy" />
      </b-button>
    </b-form>
  </primary-modal>
</template>

<script>
import PrimaryModal from "@/components/PrimaryModal.vue";
import BoxUploader from "@/components/BoxUploader";
import RingLoader from "@/components/loader/RingLoader";
export default {
  name: "EditResume",
  components: {
    PrimaryModal,
    BoxUploader,
    RingLoader,
  },
  data() {
    return {
      isBusy: false,
      form: {
        resume: null,
      },
    };
  },
  computed: {
    btnDisabled() {
      return this.form.resume === null || this.isBusy;
    },
  },
  methods: {
    handleSubmit() {
      this.isBusy = true;
      const formData = new FormData();
      formData.append("resume", this.form.resume);

      this.$store
        .dispatch("auth/updateResume", formData)
        .then(() => {
          this.$bvModal.hide("edit_resume");
        })
        .catch(() => {
          //nothing to do here
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>
