<template>
  <svg
    v-if="variant === 'primary'"
    width="13"
    height="14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.911 6.016a.547.547 0 0 0-.547-.547H2.731a.547.547 0 1 0 0 1.093h5.633a.547.547 0 0 0 .547-.546ZM2.731 7.656a.547.547 0 1 0 0 1.094h3.421a.547.547 0 1 0 0-1.094h-3.42Z"
      fill="#2758BA"
    />
    <path
      d="M4.016 12.906H2.188c-.603 0-1.093-.49-1.093-1.094V2.188c0-.604.49-1.094 1.093-1.094h6.724c.603 0 1.094.49 1.094 1.093v3.364a.547.547 0 1 0 1.093 0V2.187A2.19 2.19 0 0 0 8.912 0H2.188A2.19 2.19 0 0 0 0 2.188v9.624A2.19 2.19 0 0 0 2.188 14h1.828a.547.547 0 1 0 0-1.094Z"
      fill="#2758BA"
    />
    <path
      d="M12.067 7.918c-.64-.64-1.68-.64-2.32 0l-3.002 2.996a.548.548 0 0 0-.137.228l-.654 2.152a.547.547 0 0 0 .67.686l2.207-.611a.547.547 0 0 0 .24-.14l2.996-2.99c.64-.64.64-1.681 0-2.32ZM8.4 12.353l-1.11.308.325-1.07L9.64 9.57l.773.773-2.014 2.01Zm2.894-2.888-.106.106-.773-.774.106-.105a.547.547 0 0 1 .773.773ZM8.364 3.281H2.731a.547.547 0 1 0 0 1.094h5.633a.547.547 0 1 0 0-1.094Z"
      fill="#2758BA"
    />
  </svg>
  <svg
    v-else-if="variant === 'light'"
    width="13"
    height="14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.911 6.016a.547.547 0 0 0-.547-.547H2.731a.547.547 0 1 0 0 1.093h5.633a.547.547 0 0 0 .547-.546ZM2.731 7.656a.547.547 0 1 0 0 1.094h3.421a.547.547 0 1 0 0-1.094h-3.42Z"
      fill="#989CA0"
    />
    <path
      d="M4.016 12.906H2.188c-.603 0-1.093-.49-1.093-1.094V2.188c0-.604.49-1.094 1.093-1.094h6.724c.603 0 1.094.49 1.094 1.093v3.364a.547.547 0 1 0 1.093 0V2.187A2.19 2.19 0 0 0 8.912 0H2.188A2.19 2.19 0 0 0 0 2.188v9.624A2.19 2.19 0 0 0 2.188 14h1.828a.547.547 0 1 0 0-1.094Z"
      fill="#989CA0"
    />
    <path
      d="M12.067 7.918c-.64-.64-1.68-.64-2.32 0l-3.002 2.996a.548.548 0 0 0-.137.228l-.654 2.152a.547.547 0 0 0 .67.686l2.207-.611a.547.547 0 0 0 .24-.14l2.996-2.99c.64-.64.64-1.681 0-2.32ZM8.4 12.353l-1.11.308.325-1.07L9.64 9.57l.773.773-2.014 2.01Zm2.894-2.888-.106.106-.773-.774.106-.105a.547.547 0 0 1 .773.773ZM8.364 3.281H2.731a.547.547 0 1 0 0 1.094h5.633a.547.547 0 1 0 0-1.094Z"
      fill="#989CA0"
    />
  </svg>
</template>
<script>
export default {
  name: "EditIcon",
  props: {
    variant: {
      type: String,
      default: "primary",
      validator(value) {
        return ["primary", "light"].includes(value);
      },
    },
  },
};
</script>
