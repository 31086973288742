<template>
  <div class="settings">
    <div class="dashboard-content py-4">
      <div class="settings__container p-4">
        <h2>Personal Information</h2>
        <b-tabs class="settings-tabs">
          <b-tab title="Personal Info" active>
            <PersonalInfo
              :user="user"
              @makeEdit="$bvModal.show('edit_profile')"
              @btnTriggered="$bvModal.show('password_update')"
            />
          </b-tab>
          <b-tab title="Next of Kin">
            <ProfileInformation
              title="Next Of Kin"
              :profile-data="user.next_of_kin"
              @makeEdit="$bvModal.show('edit_next_of_kin')"
            />
          </b-tab>
          <!-- <b-tab title="Bank Details">
            <ProfileInformation
              title="Bank Details"
              :profile-data="bankDetails"
              @makeEdit="$bvModal.show('edit_bank_details')"
            />
          </b-tab> -->
          <b-tab title="Resume">
            <Resume :resume="user.resume_url" @makeEdit="$bvModal.show('edit_resume')" />
          </b-tab>
          <EditNextOfKin
            :edit-data="nextOfKin"
            :is-busy="nextOfKinIsBusy"
            @updated="updateNextOfKin"
          />
          <EditProfile :edit-data="user" :is-busy="profileIsBusy" @updated="updateProfile" />
          <!-- <EditBankDetails :edit-data="bankDetails" /> -->
          <EditResume />
          <PasswordUpdate />
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import PersonalInfo from "../../components/settings/PersonalInfo.vue";
import ProfileInformation from "../../components/settings/ProfileInformation.vue";
import Resume from "../../components/settings/Resume.vue";
import EditNextOfKin from "../../components/settings/EditNextOfKin.vue";
import EditProfile from "../../components/settings/EditProfile.vue";
// import EditBankDetails from "../../components/settings/EditBankDetails.vue";
import EditResume from "../../components/settings/EditResume.vue";
import PasswordUpdate from "../../components/settings/PasswordUpdate.vue";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  components: {
    PersonalInfo,
    ProfileInformation,
    Resume,
    EditNextOfKin,
    EditProfile,
    EditResume,
    // EditBankDetails,
    PasswordUpdate,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters({
      nextOfKin: "auth/nextOfKin",
      bankDetails: "auth/bankDetails",
      resume: "auth/resume",
      nextOfKinIsBusy: "auth/nextOfKinIsBusy",
      profileIsBusy: "auth/profileIsBusy",
    }),
  },
  methods: {
    ...mapActions({
      editUser: "auth/editUser",
      fetchUser: "auth/fetchUser",
    }),
    updateNextOfKin(payload) {
      this.$store.dispatch("auth/updateNextOfKin", payload).then(() => {
        this.$bvModal.hide("edit_next_of_kin");
      });
    },
    updateProfile(payload) {
      this.$store.dispatch("auth/updateProfile", payload).then(() => {
        this.$bvModal.hide("edit_profile");
      });
    },
    
  },
};
</script>
<style lang="scss" scoped>
.settings {
  h2 {
    color: $black-tint-3;
    font-size: 1.5rem;
    line-height: 2.625rem;
    font-weight: 600;
  }
  &__container {
    background-color: $body-bg;
  }
}
</style>
