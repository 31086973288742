<template>
  <primary-modal
    ref="editNextOfKin"
    modal-id="edit_next_of_kin"
    :title="this.isLoading ? 'Please wait' : 'Edit Next Of Kin'"
  >
    <div class="edit-next-of-kin p-3">
      <b-form ref="editNextOfKinForm" @submit="handleSubmit">
        <b-form-group label="Full name" label-for="name">
          <b-form-input id="name" v-model="nextOfKin.name"></b-form-input>
        </b-form-group>
        <b-form-group label="Relationship" label-for="relationship">
          <b-form-input id="relationship" v-model="nextOfKin.relationship"></b-form-input>
        </b-form-group>
        <b-form-group label="Email" label-for="email">
          <b-form-input id="email" v-model="nextOfKin.email" type="email"></b-form-input>
        </b-form-group>
        <b-form-group label="Phone" label-for="phone">
          <b-form-input id="phone" v-model="nextOfKin.phone"></b-form-input>
        </b-form-group>
        <b-form-group label="Address" label-for="address">
          <b-form-input id="address" v-model="nextOfKin.address"></b-form-input>
        </b-form-group>
        <b-button block type="submit" variant="primary" class="mt-5">
          {{ isLoading ? "" : "Submit" }}
          <RingLoader v-if="isLoading" />
        </b-button>
      </b-form>
    </div>
  </primary-modal>
</template>
<script>
import PrimaryModal from "@/components/PrimaryModal.vue";
import RingLoader from "@/components/loader/RingLoader";
export default {
  name: "EditNextOfKin",
  components: {
    PrimaryModal,
    RingLoader,
  },
  props: {
    editData: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nextOfKin: this.editData,
      isLoading: this.isBusy,
    };
  },
  watch: {
    isBusy(busy) {
      this.isLoading = busy
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.isLoading = true
      this.$emit("updated", this.nextOfKin)
    },
  },
};
</script>
