<template>
  <primary-modal ref="passwordUpdate" modal-id="password_update" title="Update Password">
    <div class="password-update p-3">
      <b-form ref="passwordUpdateForm" @submit="onSubmit">
        <b-form-group
          label="New Password"
          label-for="new_password"
          class="position-relative"
        >
          <b-form-input
            id="new_password"
            v-model="new_password"
            :type="newPasswordType"
            placeholder="Enter new password"
          ></b-form-input>
          <span
            class="position-absolute right-icon m-cursor-pointer"
            @click="visibleNewPass = !visibleNewPass"
          >
            <font-awesome-icon :icon="visibleNewPass ? faEye : faEyeSlash" />
          </span>
        </b-form-group>
        <b-form-group
          label="Current Password"
          label-for="current_password"
          class="position-relative"
        >
          <b-form-input
            id="current_password"
            v-model="current_password"
            :type="oldPasswordType"
            placeholder="Enter old password"
          ></b-form-input>
          <span
            class="position-absolute right-icon m-cursor-pointer"
            @click="visibleOldPass = !visibleOldPass"
          >
            <font-awesome-icon :icon="visibleOldPass ? faEye : faEyeSlash" />
          </span>
        </b-form-group>
        <b-button block type="submit" variant="primary" class="mt-5">Submit</b-button>
      </b-form>
    </div>
  </primary-modal>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import PrimaryModal from "@/components/PrimaryModal";
export default {
  name: "PasswordUpdate",
  components: {
    PrimaryModal,
    FontAwesomeIcon,
  },
  data() {
    return {
      new_password: "",
      current_password: "",
      faEye,
      faEyeSlash,
      visibleOldPass: false,
      visibleNewPass: false,
    };
  },
  computed: {
    newPasswordType() {
      return this.visibleNewPass ? "text" : "password";
    },
    oldPasswordType() {
      return this.visibleOldPass ? "text" : "password";
    },
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      console.log("onSubmit");
    },
  },
};
</script>
