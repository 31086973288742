<template>
  <div class="box-uploader">
    <div class="box-uploader__document-upload">
      <h4 class="mb-2">{{ title }} <IIcon /></h4>
      <div>
        <label
          for="file"
          class="box-uploader__upload-input m-rounded-12 m-cursor-pointer"
        >
          <b-form-file
            id="file"
            v-model="content"
            class="d-none"
            @input="handleInput"
          ></b-form-file>
          <span class="box-uploader__message m-rounded-12 p-2">
            <UploadIcon />{{ content ? content.name : "Click or Drop File" }}
          </span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import UploadIcon from "@/components/svg/UploadIcon.vue";
import IIcon from "@/components/svg/IIcon.vue";

export default {
  name: "BoxUploader",
    components: {
    UploadIcon,
    IIcon,
  },
  props: {
    file: {
      type: File,
      default: null,
    },
    title: {
      type: String,
      default: "Upload Document",
    },
  },
  data() {
    return {
      content: this.file,
    };
  },
  watch: {
    file() {
      this.content = this.file;
    },
  },
  methods: {
    handleInput() {
      this.$emit("input", this.content);
    },
  },
};
</script>
<style lang="scss" scoped>
.box-uploader {
  &__upload-input {
    background: $grey-tint-4;
    min-height: 200px;
    width: 100%;
    text-align: center;
    position: relative;
  }
  &__message {
    background: $body-bg;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  h4 {
    font-size: 1rem;
    color: $grey-tint-7;
  }
}
</style>
