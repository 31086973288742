<template>
  <b-modal
    :id="modalId"
    :ref="modalId"
    :title="title"
    content-class="primary-modal"
    dialog-class="primary-dialog"
    hide-footer
  >
    <slot> </slot>
  </b-modal>
</template>
<script>
export default {
  name: "PrimaryModal",
  props: {
    title: {
      type: String,
      default: "Primary Modal",
    },
    modalId: {
      type: String,
      required: true,
    },
  },
};
</script>
