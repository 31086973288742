<template>
  <div
    class="resume-card m-rounded-10 pt-2 pb-3 px-2 d-flex flex-column justify-content-between"
  >
    <div class="resume-card__actions d-flex justify-content-end">
      <span @click="downloadResume()"
        class="resume-card__action-container d-flex align-items-center justify-content-center border m-cursor-pointer mr-2"
      >
        <CloudIcon />
      </span>
      <span
        class="resume-card__action-container d-flex align-items-center justify-content-center m-cursor-pointer bg-secondary"
      >
        <DustbinIcon />
      </span>
    </div>
    <div class="resume-card__icon text-center">
      <FileIcon />
    </div>
    <div class="resume-card__title text-center">
      <span :title="resume.title">{{ resume.title }}</span>
    </div>
  </div>
</template>
<script>
import CloudIcon from "../svg/CloudIcon.vue";
import DustbinIcon from "../svg/DustbinIcon.vue";
import FileIcon from "../svg/FileIcon.vue";
export default {
  name: "ResumeCard",
  components: {
    CloudIcon,
    DustbinIcon,
    FileIcon,
  },
  props: {
    resume: {
      type: Object,
      required: true,
    },
  },
  methods: {
    downloadResume() {
      window.open(this.resume.url)
    }
  }
};
</script>
<style lang="scss" scoped>
.resume-card {
  width: 192px;
  height: 174px;
  border: 1px solid $grey-tint-3;
  &__action-container {
    width: 35px;
    height: 24px;
    border-radius: $size-10 / 2;
  }
  &__title {
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .bg-secondary {
    background-color: rgba(238, 129, 98, 0.1) !important;
  }
}
</style>
